module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Martin petrovický portfolio","short_name":"Martin petrovický","description":"Zabívám se tvorbou videii a focením všech možných událostí. Na mých stránkách si můžete prohlednou ukázku mé tvorby, přečíst reference či mě rovnou kontaktovat.","start_url":"/","lang":"cs","title":"Martin Petrovický | Fotograf & Video Maker","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"static/icons/android-chrome-512x512.png","icons":[{"src":"static/icons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"static/icons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"static/icons/favicon.ico","sizes":"48x48","type":"image/ico"},{"src":"static/icons/mstile-150x150.png","sizes":"150x150","type":"image/png"},{"src":"static/icons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"static/icons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/icons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"99e1cae797dcb565bccfa3b192f3868e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-NH4D8S08XV","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"G-NH4D8S08XV","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
